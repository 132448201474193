import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const FSWHLandingPage = () => (
  <PhysicianLandingPage
    physician="Four Seasons Women’s Health"
    institution="Four Seasons Women’s Health"
    referralCode="FSWH"
  />
)

export default FSWHLandingPage
